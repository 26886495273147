<template>
	<div class="cs-block cs-faq-block" :class="styleClass">
		<div class="cs-base-block">
			<div class="container">
				<CSFaq :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "FAQ",
	components: {
		CSFaq: () => import("../components/CSFaq.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: computed('FAQ')
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';
.cs-faq-block {
    &.cs-style-dark ::v-deep {
        span.cs-text {
            color: #fff !important;
            * {
                color: #fff !important;
            }
        }
        .search-input__item {
            color: #fff !important;
        }
        .card-header {
            span {
                color: #fff !important;
            }
            svg {
                fill: #fff !important;
            }
            span.dash {
                background-color: #fff !important;
            }
        }
    }
    ::v-deep {
        .header-content {
            text-align: center;
            margin-bottom: 10px;
            span.cs-text {
                p {
                    font-size: 16px !important;
                }
            }
        }

        .search-input {
            margin: 0 auto;
            width: 100%;
            margin-top: $theme_items_margin;
            display: block;
            position: relative;
            
            &__item {
                display: block;
                width: 100%;
                height: 40px;
                padding: 25px 25px 25px 45px;
                outline: none;
                color: #555;
                background: transparent;
                border: 1px solid #ccc;
                border-radius: 0;
            }

            .bi-search {
                position: absolute;
                left: 1%;
                top: 50%;
                fill: #ccc;
                transform: translateY(-50%);
                cursor: pointer;
                width: 18px;
                height: 18px;
                @media (max-width: 767px) {
                    left: 2%;
                }
            }
        }


        .buttons {
            display: flex;
            justify-content: start;
            gap: 10px;
            .grey-button,
			.tag-button {
				@include tag-btn;
			}
        }
        .card {
            background: transparent;
            border: 1px solid #ccc;
            &.bordered {
				border: 1px solid #ccc !important;
				border-radius: 0;
                margin-bottom: 0;
			}

            .card-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                cursor: pointer;
                background: none !important;
                margin-bottom: 0;
                border-radius: 0;
                border: none;
                & > div {
					width: 100%;
				}
                span:nth-child(1) {
                    @include small-title;
                    width: calc(100% - 15px);
                    display: block;
                }
            }
            .card-body {
                margin-top: 10px;
                padding-top: 15px;
                background: #eee;
                border-radius: 0 !important;
                span {
                    @include text;
                    img {
                        max-width: 100%;
                        height: 300px;
                        margin: 10px 10px 10px 0;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .buttons {
                    justify-content: start !important;
                    margin: 0 !important;
                    .tag-button {
                        background: #ffffff;
                    }
                }
            }
        }
    }
}
</style>
